.descricao {
    border: #1976d2 3px solid;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 15px;
    padding-top: 15px;
    padding: 5px;
    padding-bottom: 20px;
    height: auto;
    justify-content: left;
    text-align: left;
    border-radius: 10px;
}