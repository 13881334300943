.pergunta-container {
    border: #1976d2 3px solid;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 15px;
    padding-top: 15px;
    padding: 5px;
    padding-bottom: 20px;
    height: auto;
    border-radius: 10px;
}

.pergunta {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}