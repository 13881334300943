.intro-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

.intro {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}