.table {
    border-collapse: collapse;
    width: 70%;
    margin-left: 15%;
    background-color: #DCDCDC;
}
  
.table th,
.table td {
    border: 1px solid black;
    padding: 8px;
}